import './navbar.css';
import b3logo from '../../assets/b3exclusive-logo.png'

const Navbar = () => {
    return ( 
        <nav>
            <div className='nav-group container'>
            <div className='nav_main_logo'> <img src= {b3logo} alt='' className='img-fluid' /> </div>
            <div className='nav_link_group'>
            </div>
            </div>
        </nav>
     );
}
 
export default Navbar;