import './speakers.css';
// import data from '../../data';
import speakerData from '../../data';
import SpeakerCard from './SpeakerCard';

const SpeakerList = () => {

    const speakers = speakerData.map((speaker) => {
        return <SpeakerCard
        id = {speaker.id}
        key = {speaker.id}
        title = {speaker.title}
        position = {speaker.position}
        company = {speaker.company}
        imageUrl = {speaker.imageUrl}
        />
    })

    return ( 
        <section className='speakers_list' id="speakers">
            <h2> Event Speakers </h2>

            <div className='container speaker_group'>
                { speakers }
            </div>
            
        </section>
     );
}
 
export default SpeakerList;