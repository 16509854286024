import './ticket.css';

const Ticket = () => {
    return ( 
        <section className=' ticket' id='ticket'>
            <h2> Event Tickets </h2>

            <div className='ticket-group container'>

                <div className='ticket-item'>
                    <div className='ticket-name'>Virtual Attendance</div>
                    <div className='ticket-price'>&#8358;30,000</div>
                    <div className='ticket-point-group'>
                        <div className='ticket-point-item'>Participate in all sessions virtually with an exclusive link </div>
                        <div className='ticket-point-item'>Certificate of participation </div>
                    </div>
                    <a href='https://paystack.com/pay/b3xclusivegold' className='ticket-button' >Buy Ticket</a>
                </div>

                <div className='ticket-item'>
                    <div className='ticket-name'>Regular</div>
                    <div className='ticket-price-group'>
                        <div>Early Bird - &#8358;50,000</div>
                        <div>Late Payment - &#8358;60,000</div>
                    </div>
                    <div className='ticket-point-group'>
                        <div className='ticket-point-item'>1 full conference access </div>
                        <div className='ticket-point-item'>Certificate of participation </div>
                    </div>
                    <a href='https://paystack.com/pay/B3XclusiveStandard' className='ticket-button' >Buy Ticket</a>
                </div>


                {/* <div className='ticket-item'>
                    <div className='ticket-name'>Silver</div>
                    <div className='ticket-price'>&#8358;100,000</div>
                    <div className='ticket-point-group'>
                        <div className='ticket-point-item'>1 full conference access </div>
                        <div className='ticket-point-item'>Certificate of participation </div>
                        <div className='ticket-point-item'>Exhibition space - 3m x 3m </div>
                    </div>
                    <a href='https://paystack.com/pay/b3xclusivesilver' className='ticket-button' >Buy Ticket</a>
                </div> */}

                {/* <div className='ticket-item'>
                    <div className='ticket-name'>Gold</div>
                    <div className='ticket-price'>&#8358;150,000</div>
                    <div className='ticket-point-group'>
                        <div className='ticket-point-item'>1 full conference access </div>
                        <div className='ticket-point-item'>Certificate of participation </div>
                        <div className='ticket-point-item'>Exhibition space - 3m x 3m </div>
                        <div className='ticket-point-item'>Logo & advert in event brochure </div>
                    </div>
                    <a href='https://paystack.com/pay/b3xclusivegold' className='ticket-button' >Buy Ticket</a>
                </div> */}
            </div>
            <div className=' sponsorship-packages'>For Sponsorship packages, call us on: <span className="contact-clr">08039690112</span> or send message on instagram  <span className="contact-clr">@veronicasdaughter</span> </div>

        </section>
    );
}
 
export default Ticket;

