import Header from '../header/header';
import Highlights from '../highlights/Highlights';
// import Register from '../register/Register';
import SpeakerList from '../speakers/SpeakerList';
import Topics from '../topics/Topics';
import Ticket from '../ticket/Ticket';
import './home.css';

const Home = () => {
  return (

    <main>
      <Header />
      <Highlights />
      <Topics />
      <SpeakerList />
      <Ticket />
      {/* <Register /> */}
    </main>
  );
}
 
export default Home;