import './footer.css';

const Footer = () => {
    return ( 
        <footer>
            <p>&copy; 2024 B³ Xclusive. All rights reserved.</p>
        </footer>
     );
}
 
export default Footer;