import './speakers.css';

const SpeakerCard = (props) => {
    return ( 
        <div className='speaker_item'>
            <img className='img-fluid speaker_image' src={`./speakers-images/${props.imageUrl}`} alt={props.name} />
            <div className='speaker_title'>{props.title}</div>
            <div className='speaker_position'>{props.position}</div>
            <div className='speaker_company'>{props.company}</div>
        </div>
     );
}
 
export default SpeakerCard;