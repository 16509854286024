const speakerData = [
    {
        id : "1",
        title : "Dr Ella Chioma Ezeadilieje",
        position: 'Convener',
        company: '@veronicasdaughter',
        imageUrl: "ella-chioma.jpg",
        description : "",
    },
    {
        id : "2",
        title : "Mrs Betty Irabor (Keynote Speaker)",
        position: 'Founder',
        company: 'Genevive Magazine',
        imageUrl: "betty-irabor.jpg",
        description : "",
    },
    {
        id : "3",
        title : "Mrs Ini Abimbola (Keynote Speaker)",
        position: 'Vice President',
        company: 'Sustainability Professionals Institute of Nigeria',
        imageUrl: "ini-abimbola.jpg",
        description : "",
    },
    {
        id : "4",
        title : "Ireti Doyle",
        position: 'Actor/TV Presenter',
        company: '',
        imageUrl: "ireti-doyle.jpg",
        description : "",
    },
    {
        id : "5",
        title : "Busola Dakolo",
        position: 'CEO',
        company: 'Bukola Dakolo Images',
        imageUrl: "busola-dakolo.jpg",
        description : "",
    },
    {
        id : "6",
        title : "Okene Tareela Vicky",
        position: 'CEO',
        company: 'Dripples Cakes',
        imageUrl: "okene-taree.jpg",
        description : "",
    },
    {
        id : "7",
        title : "Oluwatoyin Aralepo",
        position: 'Director, Finance & Entrepreneurship',
        company: 'Mastercard Foundation',
        imageUrl: "oluwatoyin-aralepo.jpg",
        description : "",
    },
    {
        id : "8",
        title : "Imelda Usoro-Olaoye",
        position: 'Managing Partner',
        company: 'Thinkmint Group',
        imageUrl: "imelda-usoro.jpg",
        description : "",
    },
    {
        id : "9",
        title : "Abiola Adelana",
        position: 'Head Tourism and Creative Art Business',
        company: 'Sterling Bank',
        imageUrl: "abiola-adelana.jpg",
        description : "",
    },
    {
        id : "10",
        title : "Mosunmola Nicole Akinwamide (MNA)",
        position: 'CEO',
        company: 'IPC Events',
        imageUrl: "mosunmola-nicole.jpg",
        description : "",
    },
    {
        id : "11",
        title : "Barr Chioma Ukegbu",
        position: '',
        company: '',
        imageUrl: "default.jpg",
        description : "",
    },

    {
        id : "12",
        title : "Rita Ahunanya",
        position: 'Lead Consultant',
        company: 'Crestructure Consulting',
        imageUrl: "rita-ahunanya.jpg",
        description : "",
    },
    {
        id : "13",
        title : "Ubong Ita",
        position: 'CEO',
        company: 'Kobo Accountant',
        imageUrl: "ubong-ita.jpg",
        description : "",
    },
    
]
export default speakerData