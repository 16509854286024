import './highlight.css';

const Highlight = () => {
    return (
        <section className='event_highlight'>
            <h2>Event Highlights</h2>
            <div className='event_highlight_group'>
                <div className='event_highlight_item'>
                    <div>Personal Branding</div>
                </div> 
                <div className='event_highlight_item'>
                    <div>Life Experiences</div>
                </div>
                <div className='event_highlight_item'>
                    <div>Business Growth</div>
                </div>
                <div className='event_highlight_item'>
                    <div>Relationship Matters</div>
                </div>
            </div>
        </section>
     );
}
 
export default Highlight;