import './header.css';
import businessWoman from "../../assets/businesswoman2.png";
import b3xLogo from "../../assets/b3xlogo.png";

const Header = () => {
    return ( 
        <header className=''>
            <div className='hero_text'>
            <img src ={ b3xLogo } alt='' className='img-fluid b3xlogo' />
            <div className='event_theme'>Theme: Beyond Limits; Bridging Generations, Building Futures</div>
            <div className='event_date_time'>
                <div className='event_date'> 📆 SAT.OCTOBER 12TH, 2024</div>
                <div className='event_time'> 🕘 9:00AM PROMPT</div>
            </div>
            <a className='header_register_button' href ='#ticket'>Get Tickets</a>
            </div>
            <div className='hero_image'>
                <img src={ businessWoman } alt='business woman folding arm' className='img-fluid' />
            </div>
        </header>
     );
}
 
export default Header;