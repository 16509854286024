import './topics.css';

const Topics = () => {
    return ( 
        <section className='event_topic' id='topics'>
            <h2> Event Topics</h2>
            <div className='event_topic_group'>
                <div className='event_topic_item'>
                Building High Status Networks For the Leading Woman
                </div>
                <div className='event_topic_item'>
                Financial Management & Funding for the 21st Century Woman
                </div>
                <div className='event_topic_item'>
                Relationships , Marriage & your Mental Health
                </div>
            </div>
        </section>
     );
}
 
export default Topics;